
import { defineComponent, onBeforeMount, ref } from 'vue'
import MarkdownCore from '../components/MarkdownCore.vue'
import FileList from '../components/FileList.vue'
export default defineComponent({
  name: 'HomeView',
  components: { MarkdownCore },
  setup() {
    const viewh = ref('')
    window.onresize = () => {
      viewh.value = Number(`${document.documentElement.clientHeight}`) + 'px'
    }
    onBeforeMount(() => {
      const tmph = Number(`${document.documentElement.clientHeight}`)
      // console.log(tmph)
      viewh.value = tmph + 'px'
      // console.log(viewh)
    })
    return {
      viewh
    }
  }
})
