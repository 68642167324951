import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_markdown_core = _resolveComponent("markdown-core")!

  return (_openBlock(), _createElementBlock("div", {
    class: "home",
    style: _normalizeStyle({ height: _ctx.viewh })
  }, [
    _createVNode(_component_markdown_core)
  ], 4))
}