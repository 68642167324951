/*
 * @Description: .
 * @Version: 0.0.1
 * @Autor: OCEAN.GZY
 * @Date: 2022-07-16 13:23:06
 * @LastEditors: OCEAN.GZY
 * @LastEditTime: 2022-07-16 13:24:46
 */
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

export default (app: any) => {
  app.use(mavonEditor)
}
