import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "editor",
  style: {"height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mavon_editor = _resolveComponent("mavon-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mavon_editor, {
      modelValue: _ctx.inputtext,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputtext) = $event)),
      style: {"height":"100%"},
      toolbars: _ctx.mdOptions
    }, null, 8, ["modelValue", "toolbars"])
  ]))
}