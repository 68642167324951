/*
 * @Descripttion:
 * @version: V0.0.1
 * @Author: OCEAN.GZY
 * @Date: 2022-07-16 00:25:44
 * @LastEditors: OCEAN.GZY
 * @LastEditTime: 2022-07-16 00:25:48
 */
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
export default (app: any) => {
  app.use(ElementPlus)
}
